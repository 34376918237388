export const ErrorMessages = {
    UnknownOrMissingCountryCode: 'Unknown or missing country code.',
    EmailExistsOnMultipleClients: 'Email exists on multiple client records, please provide your Client Code.',
    PasswordLength: 'Password must be at least 6 characters long.',
    PasswordStrength: 'Password must contain at least one lowercase, one uppercase, one number and one special character.',
    EmailNotFound: "Email not found, please check that you've entered it correctly.",
    EmailAlreadyRegistered: 'Email already registered.',
    InvalidActivationCode: "Invalid activation code, please check that you've entered it correctly.",
    LoginError: "Incorrect email or password, please check that you've entered it correctly.",
    CurrentPasswordIncorrect: "Current password incorrect, please check that you've entered it correctly.",
    NewPasswordLength: 'New password must be at least 6 characters long.',
    NewPasswordStrength: 'New password must contain at least one lowercase, one uppercase, one number and one special character.',
    InvalidPhoneNumberForCountry: 'Invalid phone number for selected country.',
    InvalidEmailAddress: 'Invalid email address.',
    NoActivePolicyForUser: 'Email cannot be registered, please contact your Adviser for assistance.',
    NoActivePolicyForUserNZ: 'Email cannot be registered, please contact your Broker for assistance.',
    DuplicateEmailOnClient: 'Email is not unique, please contact your Adviser for assistance.',
    DuplicateEmailOnClientNZ: 'Email is not unique, please contact your Broker for assistance.',
    SchemeSessionNotCompleted: 'Login not permitted, please contact your Adviser for assistance.',
    SchemeSessionNotCompletedNZ: 'Login not permitted, please contact your Broker for assistance.',
    ClientNotActive: 'Login not permitted, please contact your Adviser for assistance.',
    ClientNotActiveNZ: 'Login not permitted, please contact your Broker for assistance.',
    EmailNotConfirmed: 'Account not activated, please check your email for activation details.',
    UnspecifiedError: 'An unspecified error.',
};
export const SuccessMessages = {
    RegistrationSuccessful: 'Please check your email and enter the six digit activation code.',
    PasswordResetSuccessful: 'An email containing instructions to reset your password has been sent.',
    ActivationSuccessful: 'Thank you! Your account has been activated',
    PasswordChangeSuccessful: 'Your password has been updated.',
};
