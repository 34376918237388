<import from="./shared/header-nav"></import>
<import from="./shared/footer-copyright"></import>

<loading-indicator is-loading.bind="router.isNavigating || api.isRequesting || partyApi.isRequesting"></loading-indicator>

<header-nav 
  routes.bind="routes" 
  adviser-details.bind="adviserDetails" 
  profile-menu-links.bind="profileMenuLinks" 
  user-profile.bind="userProfile" 
  on-profile-click.bind="e => onProfileClick(e)" 
  on-close-profile.bind="e => onCloseProfile(e)" 
  on-profile-item-click.bind="e => onProfileItemClick(e)"
  show-profile-menu.bind="showProfileMenu">
</header-nav>

<section class="container">
  <au-viewport></au-viewport>
</section>

<footer-copyright app-client-type.bind="appClientType" adviser-details.bind="adviserDetails"></footer-copyright>
