import development from './config/development';
import production from './config/production';
import staging from './config/staging';
let environment;
// @ts-expect-error
if (NODE_ENV?.development) {
    environment = development;
}
// @ts-expect-error
if (NODE_ENV?.production) {
    environment = production;
}
// @ts-expect-error
if (NODE_ENV?.staging) {
    environment = staging;
}
export default environment;
