import environment from '../environment';
export default {
    baseUrl: environment.appClientType === 'AU' ? environment.clientAPIHost : environment.clientAPIHostNZ,
    loginUrl: '/token',
    loginRedirect: '/',
    tokenPrefix: '',
    tokenName: 'access_token',
    authHeader: 'Authorization',
    authToken: 'Bearer',
    platform: 'browser',
    storage: 'sessionStorage',
    user_type: 'client',
    client_id: 'clientportal-js',
};
