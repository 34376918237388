<label for.bind="id" if.bind="showLabel">Phone Number</label>

<ia-flag-dropdown
  selected-country.bind="countryInfo"
  read-only.bind="readOnly"
></ia-flag-dropdown>

<input
  ref="input"
  type="phone"
  show.bind="!readOnly"
  value.bind="phoneNumber | formatPhoneNumber:countryInfo.code"
  id.bind="id"
  focus.bind="hasFocus"
/>
<label show.bind="readOnly"
  >${phoneNumber | formatPhoneNumber:countryInfo.code}</label
>
