var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __param = (this && this.__param) || function (paramIndex, decorator) {
    return function (target, key) { decorator(target, key, paramIndex); }
};
import { DI, IEventAggregator } from 'aurelia';
import { IPlatform } from '@aurelia/runtime-html';
export const IGoogleAnalytics = DI.createInterface('IGoogleAnalytics', (x) => x.singleton(GoogleAnalytics));
let GoogleAnalytics = class GoogleAnalytics {
    ea;
    platform;
    constructor(ea, platform) {
        this.ea = ea;
        this.platform = platform;
    }
    init(trackingCode) {
        const script = document.createElement('script');
        script.text =
            '(function(i,s,o,g,r,a,m){i["GoogleAnalyticsObject"]=r;i[r]=i[r]||function(){' +
                '(i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),' +
                'm=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)' +
                '})(window,document,"script","https://www.google-analytics.com/analytics.js","ga");';
        document.querySelector('body').appendChild(script);
        this.platform.window.ga =
            globalThis.ga ||
                function () {
                    // eslint-disable-next-line prefer-rest-params
                    (this.platform.window.ga.q = this.platform.window.ga.q || []).push(arguments);
                };
        this.platform.window.ga.l = +new Date();
        this.platform.window.ga(() => {
            const trackers = this.platform.window.ga.getAll();
            if (!trackers.length) {
                this.create(trackingCode, 'auto');
            }
        });
    }
    attach() {
        this.attachPageTracker();
        this.attachExceptionTracker();
    }
    /**
     * Execute
     *
     * Method calls the Google Analytics Window method
     * and tells it was we are doing.
     *
     * @param command
     * @param args
     * @return void
     */
    execute(command, args) {
        args.unshift(command);
        this.platform.window.ga.apply(this.platform.window.ga, args);
    }
    /**
     * Create
     *
     * Used for creating events.
     *
     * @param args
     * @return void
     */
    create(...args) {
        this.execute('create', args);
    }
    /**
     * Send
     *
     * Sends a Google Analytics event. Supports multiple arguments in the form of
     * the spread operator obtaining them.
     *
     * @example
     * this.ga.send('pageview', '/');
     *
     * @param args
     * @return void
     */
    send(...args) {
        this.execute('send', args);
    }
    /**
     * Set
     *
     * Sends a Google Analytics set call.
     *
     * @param {*} args
     * @return void
     */
    set(...args) {
        this.execute('set', args);
    }
    /**
     * Attach Page Tracker
     *
     * Listens for successful router changes and fires off an event to Google Analytics
     * to track page changes
     *
     * @return void
     */
    attachPageTracker() {
        this.ea.subscribe('router:navigation:success', (payload) => {
            this.set('page', payload.instruction.fragment, payload.instruction.title || document.title);
            this.send('pageview');
        });
    }
    attachExceptionTracker() {
        const windowOnerror = window.onerror;
        window.onerror = (errorMessage, url, lineNumber, columnNumber, errorObject) => {
            if (typeof this.platform.window.ga === 'function') {
                let exceptionDescription;
                if (errorObject !== undefined && typeof errorObject.message !== undefined) {
                    exceptionDescription = errorObject.message;
                }
                else {
                    exceptionDescription = errorMessage;
                }
                exceptionDescription += ' @ ' + url;
                if (lineNumber !== undefined && columnNumber !== undefined) {
                    exceptionDescription += ':' + lineNumber + ':' + columnNumber;
                }
                this.send('exception', {
                    exDescription: exceptionDescription,
                    exFatal: false,
                });
            }
            if (typeof windowOnerror === 'function') {
                return windowOnerror(errorMessage, url, lineNumber, columnNumber, errorObject);
            }
            return false;
        };
    }
};
GoogleAnalytics = __decorate([
    __param(0, IEventAggregator),
    __param(1, IPlatform),
    __metadata("design:paramtypes", [Object, Object])
], GoogleAnalytics);
export { GoogleAnalytics };
