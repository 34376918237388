var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __param = (this && this.__param) || function (paramIndex, decorator) {
    return function (target, key) { decorator(target, key, paramIndex); }
};
import { log } from '../../resources/utilities/logger';
import { IHttpClient } from '@aurelia/fetch-client';
import { IHttpUtilities } from '../utilities/http-utilities';
import { IHttpConfig } from '../../config/http.config';
import authConfig from '../../config/auth.config';
import { IAppService } from './app-service';
import { DI } from 'aurelia';
export const IPortalService = DI.createInterface('IPortalService', (x) => x.singleton(PortalService));
let PortalService = class PortalService {
    http;
    httpConfig;
    httpUtilities;
    appService;
    host;
    constructor(http, httpConfig, httpUtilities, appService) {
        this.http = http;
        this.httpConfig = httpConfig;
        this.httpUtilities = httpUtilities;
        this.appService = appService;
        this.http = httpConfig.configure(http);
        this.host = appService.apiHost;
    }
    async getClientCredentialsToken() {
        try {
            const tokenResponse = await this.http.fetch(`${this.host}/token`, {
                method: 'POST',
                headers: {
                    'content-type': 'application/x-www-form-urlencoded',
                },
                body: `grant_type=client_credentials&client_secret=2dc34b863374412491f64cfb427c6daa&client_id=${authConfig.client_id}&app_client=${this.appService.appClientType}&app_instance=${this.appService.appClientInstance}`,
            });
            return await tokenResponse.json();
        }
        catch (err) {
            log.error(err);
        }
    }
    async getClientProfile() {
        try {
            const options = this.httpUtilities.createGetOptions();
            const profile = await this.http.fetch(`${this.host}/portal/profile`, options);
            const result = await profile.json();
            return result;
        }
        catch (err) {
            log.error(err);
        }
    }
    async getAdviserDetails() {
        try {
            const options = this.httpUtilities.createGetOptions();
            const adviser = await this.http.fetch(`${this.host}/portal/adviserdetails`, options);
            const result = await adviser.json();
            return result;
        }
        catch (err) {
            log.error(err);
        }
    }
    async postAdviserDetailsContactUsEmail(subject, feedback) {
        try {
            const options = this.httpUtilities.createPostOptions({
                body: {
                    Subject: subject,
                    Message: feedback,
                },
            });
            const sendContactUsEmail = await this.http.fetch(`${this.host}/portal/adviserdetails/email`, options);
            return sendContactUsEmail.status;
        }
        catch (err) {
            log.error(err);
        }
    }
    async postChangePassword(oldPassword, newPassword, confirmPassword) {
        try {
            const changePassword = await this.http.fetch(`${this.host}/api/account/changepassword`, {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                },
                body: JSON.stringify({
                    OldPassword: oldPassword,
                    NewPassword: newPassword,
                    ConfirmPassword: confirmPassword,
                }),
            });
            if (changePassword.status === 400) {
                const changeResult = await changePassword.json();
                // Current Password Incorrect
                if (changeResult.hasOwnProperty('PasswordMismatch')) {
                    return -1;
                }
                // password length
                if (changeResult.hasOwnProperty('NewPassword') || changeResult.errors?.hasOwnProperty('NewPassword')) {
                    return -2;
                }
                // password strength
                if (changeResult.hasOwnProperty('PasswordRequiresDigit') ||
                    changeResult.hasOwnProperty('PasswordRequiresNonAlphanumeric') ||
                    changeResult.hasOwnProperty('PasswordRequiresUpper')) {
                    return -3;
                }
            }
            return changePassword.status;
        }
        catch (err) {
            log.error(err);
        }
    }
    async postResetPassword(email) {
        try {
            const token = await this.getClientCredentialsToken();
            const url = `${this.host}/api/account/resetpassword/generatelink?email=${email}&returnUrl=${window.location.href}`;
            const sendReset = await this.http.fetch(url, {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token.access_token}`,
                },
            });
            if (sendReset.status !== 200) {
                const resetResult = await sendReset.json();
                // user not found
                if (resetResult.hasOwnProperty('userNotFound')) {
                    return -4;
                }
            }
            return sendReset.status;
        }
        catch (err) {
            log.error(err);
        }
    }
    async postRegisterNewUser(email, password, confirmPassword, clientCode) {
        try {
            const token = await this.getClientCredentialsToken();
            const sendRegister = await this.http.fetch(`${this.host}/api/account/register/client`, {
                method: 'POST',
                body: JSON.stringify({
                    Email: email,
                    Username: email,
                    Password: password,
                    ConfirmPassword: confirmPassword,
                    ClientCode: clientCode,
                }),
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token.access_token}`,
                },
            });
            const regResult = await sendRegister.json();
            if (sendRegister.status === 400) {
                // email address used on more than one client record
                // user to provide client code
                if (regResult.hasOwnProperty('clientCode') && !regResult.hasOwnProperty('id')) {
                    return -1;
                }
                // new password length
                if (regResult.hasOwnProperty('passwordTooShort')) {
                    return -2;
                }
                // new password strength
                if (regResult.hasOwnProperty('passwordRequiresDigit') ||
                    regResult.hasOwnProperty('passwordRequiresNonAlphanumeric') ||
                    regResult.hasOwnProperty('passwordRequiresUpper')) {
                    return -3;
                }
                // user not found
                if (regResult.hasOwnProperty('userNotFound')) {
                    return -4;
                }
                // email already registered
                if (regResult.hasOwnProperty('DuplicateUserName')) {
                    return -5;
                }
                // no active policy for user
                if (regResult.hasOwnProperty('userNoPolicy')) {
                    return -6;
                }
                // duplicate emails found for client
                if (regResult.hasOwnProperty('clientHasMultipleEmails')) {
                    return -7;
                }
                // scheme registration complete, but policy not created
                if (regResult.hasOwnProperty('SchemeSessionNotCompleted')) {
                    return -8;
                }
                // client inactive
                if (regResult.hasOwnProperty('ClientNotActive')) {
                    return -9;
                }
            }
            return regResult;
        }
        catch (err) {
            log.error(err);
        }
    }
    async getActivateAccount(userId, activationCode) {
        let url = `${this.host}/api/account/confirmemail?userId=${userId}&code=${activationCode}`;
        try {
            const token = await this.getClientCredentialsToken();
            const result = await this.http.fetch(url, {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token.access_token}`,
                },
            });
            if (result.status == 200) {
                return true;
            }
            return false;
        }
        catch (err) {
            log.error(err);
        }
    }
    async resendActivationCode(email) {
        let url = `${this.host}/api/account/sendemailactivation?email=${email}`;
        try {
            const result = await this.http.fetch(url, {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
            });
            if (result.status == 200) {
                return true;
            }
            return false;
        }
        catch (err) {
            log.error(err);
        }
    }
};
PortalService = __decorate([
    __param(0, IHttpClient),
    __param(1, IHttpConfig),
    __param(2, IHttpUtilities),
    __param(3, IAppService),
    __metadata("design:paramtypes", [Object, Object, Object, Object])
], PortalService);
export { PortalService };
