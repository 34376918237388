import { AppClientTask } from './app-tasks/app-client';
import Aurelia, { PLATFORM } from 'aurelia';
import { RouterConfiguration } from '@aurelia/router';
import { ValidationHtmlConfiguration } from '@aurelia/validation-html';
import { AureliaAuthConfiguration, IAuthService } from 'aurelia2-auth';
import { AureliaNotification } from 'aurelia2-notification';
import { GoogleMapsConfiguration } from 'aurelia2-google-maps';
import { GoogleAnalyticsTask } from './app-tasks/google-analytics';
import { RouteLoadedHook, RouterAuthHook } from './lifecycle-hooks/route-hook';
let appClientType;
let appClientInstance;
import AuthConfig from './config/auth.config';
import { MyApp } from './my-app';
import 'bootstrap/dist/css/bootstrap.css';
import '../static/fontawesome-all';
import './branding.css';
import './app.css';
import './shared/jackedup.css';
import { LoginRoot } from './components/login-root';
import { Components } from './resources';
import { AttachedHook } from './lifecycle-hooks/attached-hook';
import environment from './environment';
if (environment.localhost) {
    appClientType = environment.appClientType;
    appClientInstance = environment.appClientInstance;
}
// Sets the appClientType and appClientInstance here before anything else so state instantiates with appclienttype and appclientinstance values
const appClientTypeTag = PLATFORM.document.querySelector('head meta[name="appclienttype"]');
const appClientInstanceTag = PLATFORM.document.querySelector('head meta[name="appclientinstance"]');
if (appClientTypeTag && appClientInstanceTag) {
    appClientType = appClientTypeTag.getAttribute('content').toUpperCase();
    appClientInstance = appClientInstanceTag.getAttribute('content').toUpperCase();
    appClientTypeTag.remove();
    appClientInstanceTag.remove();
}
const aurelia = new Aurelia();
aurelia.register(Components, RouterConfiguration.customize({
    useUrlFragmentHash: false,
    // useDirectRouting: false,
    useHref: false,
    title: '${componentTitles}${appTitleSeparator}IA Client Portal',
    swapOrder: 'attach-detach-simultaneously',
}), RouterAuthHook, RouteLoadedHook, AttachedHook, ValidationHtmlConfiguration, AureliaAuthConfiguration.configure(AuthConfig), AureliaNotification.configure({
    translate: false,
    notifications: {
        success: 'humane-jackedup-success',
        error: 'humane-jackedup-error',
        info: 'humane-jackedup-info',
    },
}), GoogleMapsConfiguration.configure({
    apiKey: 'AIzaSyDbkP7_mTqzpKRAvbvZF1iJ524dnIxVYos',
    //apiLibraries: 'drawing,geometry', //get optional libraries like drawing, geometry, ... - comma seperated list
    options: {
        backgroundColor: '#495061'
    },
    //language: 'en', // default: uses browser configuration (recommended). Set this parameter to set another language (https://developers.google.com/maps/documentation/javascript/localization)
    region: 'AU' // default: it applies a default bias for application behavior towards the United States. (https://developers.google.com/maps/documentation/javascript/localization)
}), GoogleAnalyticsTask, AppClientTask(appClientType, appClientInstance));
const isAuthenticated = aurelia.container.get(IAuthService).isAuthenticated();
const initialComponent = isAuthenticated ? MyApp : LoginRoot;
aurelia.app(initialComponent).start();
