export default {
    debug: true,
    localhost: true,
    testing: true,
    appClientType: 'AU',
    appClientInstance: "IA",
    clientAPIHost: 'https://dev0-clientportalapi-dev.azurewebsites.net',
    clientAPIHostNZ: 'https://dev0-clientportalapinz-dev.azurewebsites.net',
    addressFinderKey: '8KV7JQUERNW9TBGFMD4H',
};
