export const CacheKey = {
    AdditionalInformation: 'ehtu847392ok',
    AdviserDetails: 'ad01923',
    CountryTypes: 'alskdjf93810la',
    EmailTypes: '93ue72wu90qasw12',
    MarketFlags: 'asdf0jfp23ef90j0',
    QuestionsDutyOfDisclosure: 'alkjsdf9823475',
    QuestionsDutyOfDisclosureForInsurers: 'yrueywhd874hdj',
    PartyPhones: 'uyestgbh75846ksn',
    PartyEmails: 'laksjs84736',
    PartyAddresses: '89di3k2',
    PhoneTypes: 'hajs8392',
    PolicyGeneral: 'pgiu8476',
    PolicyInsuredInformation: 'pii98736',
    PolicyLocationAndRisk: 'plr12yru76',
    PolicyInsurerInformation: 'piri8ujr5243',
    CountryList: 'lchusal9234',
};
export const FormState = {
    Add: 'add',
    Update: 'update',
    Delete: 'delete',
};
export const FilterKeys = {
    TypesThatCanBeAppliedToClients: '600000003',
};
export const DateFormats = {
    StandardDate: 'DD/MM/YYYY',
    ShortDateDayMonth: 'DD/MM',
    ShortDateMonthYear: 'MM/YYYY',
};
export const CurrencyFormats = {
    Dollar: '$0,0.00',
    DollarNoCents: '$0,0',
};
export const NumberFormats = {
    CommaDelimitedNoDecimalPlaces: '0,0',
    CommaDelimitedTwoDecimalPlaces: '0,0.00',
};
