import { DI } from 'aurelia';
import environment from '../../environment';
export const IAppService = DI.createInterface('IAppService', (x) => x.singleton(AppService));
export class AppService {
    __appClientType;
    __appClientInstance;
    get appClientType() {
        return this.__appClientType;
    }
    get appClientInstance() {
        return this.__appClientInstance;
    }
    setAppClientType(appClientType) {
        this.__appClientType = appClientType;
    }
    setAppClientInstance(appClientInstance) {
        this.__appClientInstance = appClientInstance;
    }
    get apiHost() {
        const apiHost = this.__appClientType === 'AU' ? environment.clientAPIHost : environment.clientAPIHostNZ;
        return typeof apiHost === 'undefined' || apiHost === null ? '' : apiHost;
    }
}
