<footer>
  <div class="container footer-flex">
    <div>
      <h3>${appClientType==='AU'?'Adviser':'Broker'}</h3>
      <h4>${adviserDetails.adviser}</h4>
    </div>

    <div>
      <h3>Practice</h3>
      <h4>${adviserDetails.companyOffice}</h4>
    </div>

    <div>
      <h3>Contact</h3>
      <ul>
        <li repeat.for="phone of adviserDetails.phones">
          <a href="tel:${phone.displayNumber}">${phone.displayNumber}</a>
        </li>
        <li>
          <a href="mailto:${adviserDetails.adviserEmailAddress}" target="_top">${adviserDetails.adviserEmailAddress}</a>
        </li>
      </ul>
    </div>

    <div>
      <h3>Links</h3>
      <ul>
        <li>
          <a href="${adviserDetails.adviserFsgUrl}" target="_blank">
            <span if.bind="appClientType==='AU'">Financial Services Guide</span>
            <span if.bind="appClientType==='NZ'">Statement of Services</span>
          </a>
        </li>
        <li>
          <a href="${adviserDetails.companyApplicationUrl}" target="_blank">IA Client App</a>
        </li>
        <!-- <li>
          <a href="https://ianet.sharepoint.com/:b:/t/ia/practices/ESMdDLKq7ppHinAzZknxfOkBudyPBhUE10Wh10iLleO1XA" target="_blank">FAQ</a>
        </li> -->
      </ul>
    </div>

    <div class="follow-us" if.bind="adviserDetails.adviserFacebook || adviserDetails.adviserTwitter || adviserDetails.adviserLinkedIn">
      <h3>Follow Us</h3>
      <ul>
        <li if.bind="adviserDetails.adviserFacebook">
          <a href="${adviserDetails.adviserFacebook}" target="_blank">
            <img class="social-icon" src="../../static/facebook-logo-white.png" alt="Facebook">
          </a>
        </li>
        <li if.bind="adviserDetails.adviserTwitter" class="twitter-icon">
          <a href="${adviserDetails.adviserTwitter}" target="_blank">
            <img class="social-icon" src="../../static/twitter-logo-white.png" alt="Twitter">
          </a>
        </li>
        <li if.bind="adviserDetails.adviserLinkedIn">
          <a href="${adviserDetails.adviserLinkedIn}" target="_blank">
            <img class="social-icon" src="../../static/linkedin-logo-white.png" alt="LinkedIn">
          </a>
        </li>
      </ul>
    </div>

  </div>

  <div class="copyright">
    <div class="container copyright-flex">

      <div>
        ${adviserDetails.currentYear} © IA Client Portal by
        <span><a href="https://www.iaanyware.net/" target="_blank">iaAnyware Pty Limited</a></span>
      </div>

      <div>
        <ul>
          <li>powered by
            <a href="${adviserDetails.companyUrl}" target="_blank">
              <img if.bind="appClientType==='AU'" src="../../static/footer-logo.png" alt="Insurance Advisernet">
              <img if.bind="appClientType==='NZ'" src="../../static/footer-logo-nz.png" alt="Insurance Advisernet">
            </a>
          </li>
          <li>
            <a href="${adviserDetails.companyPrivacyUrl}" target="_blank">Privacy</a>
          </li>
        </ul>
      </div>

    </div>
  </div>
</footer>