var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __param = (this && this.__param) || function (paramIndex, decorator) {
    return function (target, key) { decorator(target, key, paramIndex); }
};
import { IPlatform, lifecycleHooks } from 'aurelia';
let AttachedHook = class AttachedHook {
    platform;
    constructor(platform) {
        this.platform = platform;
    }
    loading(vm, params, next, current) {
        if (next?.component?.name) {
            const pageClass = new RegExp(/\bpage-.+?\b/, 'g');
            if (this.platform.document.body.className.match(pageClass)) {
                this.platform.document.body.className = this.platform.document.body.className.replace(pageClass, '');
            }
            this.platform.document.body.classList.add(`page-${next.component.name}`);
        }
    }
};
AttachedHook = __decorate([
    lifecycleHooks(),
    __param(0, IPlatform),
    __metadata("design:paramtypes", [Object])
], AttachedHook);
export { AttachedHook };
