export default {
    debug: false,
    localhost: false,
    testing: false,
    appClientType: 'AU',
    appClientInstance: "IA",
    clientAPIHost: '',
    clientAPIHostNZ: '',
    addressFinderKey: '8KV7JQUERNW9TBGFMD4H',
};
