<div class="selected-country" click.trigger="expanded = true">
  <div class="flag flag-${selectedCountry.code}"></div>
  <div class="caret" if.bind="!readOnly"></div>
</div>

<div show.bind="expanded && !readOnly" class="country-list" role="combobox">
  <div
    repeat.for="country of topCountries | countryCodeToCountryInfo:countries"
    class="country ${ selectedCountry.code === country.code ? 'selected' : ''}"
    aria-selected.bind="selectedCountry === country"
    click.trigger="selectCountry(country)"
  >
    <div class="flag flag-${country.code}"></div>
    <div class="country-name">${country.name}</div>
    <div class="dialing-code">+${country.dialingCode}</div>
  </div>
  <div class="divider"></div>
  <div
    repeat.for="country of countries | filterCountries:topCountries"
    class="country ${ selectedCountry.code === country.code ? 'selected' : ''}"
    aria-selected.bind="selectedCountry === country"
    click.trigger="selectCountry(country)"
  >
    <div class="flag flag-${country.code}"></div>
    <div class="country-name">${country.name}</div>
    <div class="dialing-code">+${country.dialingCode}</div>
  </div>
</div>
