import { json } from '@aurelia/fetch-client';
import { DI } from 'aurelia';
export const IHttpUtilities = DI.createInterface('IHttpUtilities', (x) => x.singleton(HttpUtilities));
export class HttpUtilities {
    createGetOptions() {
        return {
            method: 'GET',
            mode: 'cors',
        };
    }
    createPostOptions({ body }) {
        return {
            method: 'POST',
            body: json(body),
        };
    }
    createPutOptions({ body }) {
        return {
            method: 'PUT',
            body: json(body),
        };
    }
    createDeleteOptions() {
        return {
            method: 'DELETE',
            mode: 'cors',
        };
    }
}
