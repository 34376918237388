var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __param = (this && this.__param) || function (paramIndex, decorator) {
    return function (target, key) { decorator(target, key, paramIndex); }
};
import { bindable, customElement, valueConverter, INode, } from 'aurelia';
import './index.css';
import template from './index.html';
let IaFlagDropdown = class IaFlagDropdown {
    element;
    topCountries = ['AU', 'NZ'];
    countries = countryList;
    selectedCountry;
    expanded = false;
    readOnly = false;
    constructor(element) {
        this.element = element;
    }
    bound() {
        const defaultCountryCode = this.topCountries[0] || 'US';
        const defaultCountry = this.countries.find((c) => c.code === defaultCountryCode);
        if (!defaultCountry) {
            throw new Error('Unable to determine default country for flag dropdown');
        }
        if (!this.selectedCountry) {
            this.selectedCountry = defaultCountry;
        }
        else if (typeof this.selectedCountry === 'string') {
            const index = this.countries.findIndex((c) => c.code === this.selectedCountry);
            if (index > -1) {
                this.selectedCountry = this.countries[index];
            }
            else {
                this.selectedCountry = defaultCountry;
            }
        }
        else if (this.selectedCountry.code) {
            const index = this.countries.findIndex((c) => c.code === this.selectedCountry.code);
            if (index > -1) {
                this.selectedCountry = this.countries[index];
            }
            else {
                this.selectedCountry = defaultCountry;
            }
        }
        else {
            throw new Error('selectedCountry with no country code');
        }
    }
    selectCountry(country) {
        this.selectedCountry = country;
        this.expanded = false;
        const event = new CustomEvent('change', {
            detail: this.selectedCountry,
        });
        this.element.dispatchEvent(event);
    }
    globalClickHandler = (event) => {
        if (this.expanded === true &&
            !this.element.contains(event.target)) {
            this.expanded = false;
        }
    };
    globalKeyDownHandler = (event) => {
        if (this.expanded === true &&
            event.keyCode === 27 &&
            !event.altKey &&
            !event.ctrlKey &&
            !event.shiftKey &&
            !event.metaKey) {
            this.expanded = false;
        }
    };
    attached() {
        document.addEventListener('click', this.globalClickHandler, false);
        document.addEventListener('keydown', this.globalKeyDownHandler, false);
    }
    detached() {
        document.removeEventListener('click', this.globalClickHandler, false);
        document.removeEventListener('click', this.globalKeyDownHandler, false);
    }
};
__decorate([
    bindable,
    __metadata("design:type", Array)
], IaFlagDropdown.prototype, "topCountries", void 0);
__decorate([
    bindable({ mode: 6 /* BindingMode.twoWay */ }),
    __metadata("design:type", Object)
], IaFlagDropdown.prototype, "selectedCountry", void 0);
__decorate([
    bindable,
    __metadata("design:type", Object)
], IaFlagDropdown.prototype, "readOnly", void 0);
IaFlagDropdown = __decorate([
    customElement({
        name: 'ia-flag-dropdown',
        template,
    }),
    __param(0, INode),
    __metadata("design:paramtypes", [HTMLElement])
], IaFlagDropdown);
export { IaFlagDropdown };
let FilterCountries = class FilterCountries {
    toView(value, countriesToFilter) {
        if (value && value.length > 0) {
            return value.filter((c) => !countriesToFilter.includes(c.code));
        }
        return value;
    }
};
FilterCountries = __decorate([
    valueConverter('filterCountries')
], FilterCountries);
export { FilterCountries };
let CountryCodeToCountryInfo = class CountryCodeToCountryInfo {
    toView(value, countries) {
        if (value && countries && countries.length > 0) {
            return value.map((code) => countries.find((c) => c.code === code));
        }
        return [
            {
                code: '',
                name: '',
                dialingCode: '',
            },
        ];
    }
};
CountryCodeToCountryInfo = __decorate([
    valueConverter('countryCodeToCountryInfo')
], CountryCodeToCountryInfo);
export { CountryCodeToCountryInfo };
export function getCountryInfoByCountryCode(countryCode) {
    const index = countryList.findIndex((c) => c.code === countryCode);
    if (index >= 0) {
        return countryList[index];
    }
    throw new Error('Unknown country code');
}
export function getCountryInfoByDialingCode(dialingCode) {
    // special case for +1
    switch (dialingCode) {
        case '61':
            return getCountryInfoByCountryCode('AU');
        case '1':
            return getCountryInfoByCountryCode('US');
        case '44':
            return getCountryInfoByCountryCode('GB');
        case '39':
            return getCountryInfoByCountryCode('IT');
        case '47':
            return getCountryInfoByCountryCode('NO');
        case '358':
            return getCountryInfoByCountryCode('FI');
        case '7':
            return getCountryInfoByCountryCode('RU');
        case '689':
            return getCountryInfoByCountryCode('PF');
        case '672':
            return getCountryInfoByCountryCode('NF');
        case '212':
            return getCountryInfoByCountryCode('MA');
        case '262':
            return getCountryInfoByCountryCode('RE');
        default:
            const index = countryList.findIndex((c) => c.dialingCode == dialingCode);
            if (index >= 0) {
                return countryList[index];
            }
            throw new Error('Unknown dialing code');
    }
}
const countryList = [
    { code: 'AF', name: 'AFGHANISTAN', dialingCode: '93' },
    { code: 'AX', name: 'ÅLAND ISLANDS', dialingCode: '358' },
    { code: 'AL', name: 'ALBANIA', dialingCode: '355' },
    { code: 'DZ', name: 'ALGERIA', dialingCode: '213' },
    { code: 'AS', name: 'AMERICAN SAMOA', dialingCode: '1' },
    { code: 'AD', name: 'ANDORRA', dialingCode: '376' },
    { code: 'AO', name: 'ANGOLA', dialingCode: '244' },
    { code: 'AI', name: 'ANGUILLA', dialingCode: '1' },
    { code: 'AQ', name: 'ANTARCTICA', dialingCode: '672' },
    { code: 'AG', name: 'ANTIGUA AND BARBUDA', dialingCode: '1' },
    { code: 'AR', name: 'ARGENTINA', dialingCode: '54' },
    { code: 'AM', name: 'ARMENIA', dialingCode: '374' },
    { code: 'AW', name: 'ARUBA', dialingCode: '297' },
    { code: 'AU', name: 'AUSTRALIA', dialingCode: '61' },
    { code: 'AT', name: 'AUSTRIA', dialingCode: '43' },
    { code: 'AZ', name: 'AZERBAIJAN', dialingCode: '991' },
    { code: 'BS', name: 'BAHAMAS', dialingCode: '1' },
    { code: 'BH', name: 'BAHRAIN', dialingCode: '973' },
    { code: 'BD', name: 'BANGLADESH', dialingCode: '880' },
    { code: 'BB', name: 'BARBADOS', dialingCode: '1' },
    { code: 'BY', name: 'BELARUS', dialingCode: '375' },
    { code: 'BE', name: 'BELGIUM', dialingCode: '32' },
    { code: 'BZ', name: 'BELIZE', dialingCode: '501' },
    { code: 'BJ', name: 'BENIN', dialingCode: '229' },
    { code: 'BM', name: 'BERMUDA', dialingCode: '1' },
    { code: 'BT', name: 'BHUTAN', dialingCode: '975' },
    { code: 'BO', name: 'BOLIVIA', dialingCode: '591' },
    { code: 'BA', name: 'BOSNIA AND HERZEGOVINA', dialingCode: '387' },
    { code: 'BW', name: 'BOTSWANA', dialingCode: '267' },
    { code: 'BR', name: 'BRAZIL', dialingCode: '55' },
    { code: 'IO', name: 'BRITISH INDIAN OCEAN TERRITORY', dialingCode: '246' },
    { code: 'BN', name: 'BRUNEI DARUSSALAM', dialingCode: '673' },
    { code: 'BG', name: 'BULGARIA', dialingCode: '359' },
    { code: 'BF', name: 'BURKINA FASO', dialingCode: '226' },
    { code: 'BI', name: 'BURUNDI', dialingCode: '257' },
    { code: 'KH', name: 'CAMBODIA', dialingCode: '855' },
    { code: 'CM', name: 'CAMEROON', dialingCode: '237' },
    { code: 'CA', name: 'CANADA', dialingCode: '1' },
    { code: 'CV', name: 'CAPE VERDE', dialingCode: '238' },
    { code: 'KY', name: 'CAYMAN ISLANDS', dialingCode: '1' },
    { code: 'CF', name: 'CENTRAL AFRICAN REPUBLIC', dialingCode: '236' },
    { code: 'TD', name: 'CHAD', dialingCode: '235' },
    { code: 'CL', name: 'CHILE', dialingCode: '56' },
    { code: 'CN', name: 'CHINA', dialingCode: '86' },
    { code: 'CX', name: 'CHRISTMAS ISLAND', dialingCode: '61' },
    { code: 'CC', name: 'COCOS (KEELING) ISLANDS', dialingCode: '61' },
    { code: 'CO', name: 'COLOMBIA', dialingCode: '57' },
    { code: 'KM', name: 'COMOROS', dialingCode: '269' },
    { code: 'CG', name: 'CONGO - BRAZZAVILLE', dialingCode: '242' },
    { code: 'CD', name: 'CONGO - KINSHASA', dialingCode: '243' },
    { code: 'CK', name: 'COOK ISLANDS', dialingCode: '682' },
    { code: 'CR', name: 'COSTA RICA', dialingCode: '506' },
    { code: 'CI', name: "CÔTE D'IVOIRE", dialingCode: '225' },
    { code: 'HR', name: 'CROATIA', dialingCode: '385' },
    { code: 'CU', name: 'CUBA', dialingCode: '53' },
    { code: 'CY', name: 'CYPRUS', dialingCode: '357' },
    { code: 'CZ', name: 'CZECH REPUBLIC', dialingCode: '420' },
    { code: 'DK', name: 'DENMARK', dialingCode: '45' },
    { code: 'DJ', name: 'DJIBOUTI', dialingCode: '253' },
    { code: 'DM', name: 'DOMINICA', dialingCode: '1' },
    { code: 'DO', name: 'DOMINICAN REPUBLIC', dialingCode: '1' },
    { code: 'EC', name: 'ECUADOR', dialingCode: '593' },
    { code: 'EG', name: 'EGYPT', dialingCode: '20' },
    { code: 'SV', name: 'EL SALVADOR', dialingCode: '503' },
    { code: 'GQ', name: 'EQUATORIAL GUINEA', dialingCode: '240' },
    { code: 'ER', name: 'ERITREA', dialingCode: '291' },
    { code: 'EE', name: 'ESTONIA', dialingCode: '372' },
    { code: 'ET', name: 'ETHIOPIA', dialingCode: '251' },
    {
        code: 'FK',
        name: 'FALKLAND ISLANDS (ISLAS MALVINAS)',
        dialingCode: '500',
    },
    { code: 'FO', name: 'FAROE ISLANDS', dialingCode: '298' },
    { code: 'FJ', name: 'FIJI', dialingCode: '679' },
    { code: 'FI', name: 'FINLAND', dialingCode: '358' },
    { code: 'FR', name: 'FRANCE', dialingCode: '33' },
    { code: 'GF', name: 'FRENCH GUIANA', dialingCode: '594' },
    { code: 'PF', name: 'FRENCH POLYNESIA', dialingCode: '689' },
    { code: 'TF', name: 'FRENCH SOUTHERN TERRITORIES', dialingCode: '689' },
    { code: 'GA', name: 'GABON', dialingCode: '241' },
    { code: 'GM', name: 'GAMBIA', dialingCode: '220' },
    { code: 'GE', name: 'GEORGIA', dialingCode: '995' },
    { code: 'DE', name: 'GERMANY', dialingCode: '49' },
    { code: 'GH', name: 'GHANA', dialingCode: '233' },
    { code: 'GI', name: 'GIBRALTAR', dialingCode: '350' },
    { code: 'GR', name: 'GREECE', dialingCode: '30' },
    { code: 'GL', name: 'GREENLAND', dialingCode: '299' },
    { code: 'GD', name: 'GRENADA', dialingCode: '1' },
    { code: 'GP', name: 'GUADELOUPE', dialingCode: '590' },
    { code: 'GU', name: 'GUAM', dialingCode: '1' },
    { code: 'GT', name: 'GUATEMALA', dialingCode: '502' },
    { code: 'GG', name: 'GUERNSEY', dialingCode: '44' },
    { code: 'GN', name: 'GUINEA', dialingCode: '224' },
    { code: 'GW', name: 'GUINEA-BISSAU', dialingCode: '245' },
    { code: 'GY', name: 'GUYANA', dialingCode: '592' },
    { code: 'HT', name: 'HAITI', dialingCode: '509' },
    { code: 'HN', name: 'HONDURAS', dialingCode: '504' },
    { code: 'HK', name: 'HONG KONG', dialingCode: '852' },
    { code: 'HU', name: 'HUNGARY', dialingCode: '36' },
    { code: 'IS', name: 'ICELAND', dialingCode: '354' },
    { code: 'IN', name: 'INDIA', dialingCode: '91' },
    { code: 'ID', name: 'INDONESIA', dialingCode: '62' },
    { code: 'IR', name: 'IRAN', dialingCode: '98' },
    { code: 'IQ', name: 'IRAQ', dialingCode: '964' },
    { code: 'IE', name: 'IRELAND', dialingCode: '353' },
    { code: 'IM', name: 'ISLE OF MAN', dialingCode: '44' },
    { code: 'IL', name: 'ISRAEL', dialingCode: '972' },
    { code: 'IT', name: 'ITALY', dialingCode: '39' },
    { code: 'JM', name: 'JAMAICA', dialingCode: '1' },
    { code: 'JP', name: 'JAPAN', dialingCode: '81' },
    { code: 'JE', name: 'JERSEY', dialingCode: '44' },
    { code: 'JO', name: 'JORDAN', dialingCode: '962' },
    { code: 'KZ', name: 'KAZAKHSTAN', dialingCode: '7' },
    { code: 'KE', name: 'KENYA', dialingCode: '254' },
    { code: 'KI', name: 'KIRIBATI', dialingCode: '686' },
    { code: 'KP', name: 'NORTH KOREA', dialingCode: '850' },
    { code: 'KR', name: 'SOUTH KOREA', dialingCode: '82' },
    { code: 'KW', name: 'KUWAIT', dialingCode: '965' },
    { code: 'KG', name: 'KYRGYZSTAN', dialingCode: '996' },
    {
        code: 'LA',
        name: "LAO PEOPLE'S DEMOCRATIC REPUBLIC",
        dialingCode: '856',
    },
    { code: 'LV', name: 'LATVIA', dialingCode: '371' },
    { code: 'LB', name: 'LEBANON', dialingCode: '961' },
    { code: 'LS', name: 'LESOTHO', dialingCode: '266' },
    { code: 'LR', name: 'LIBERIA', dialingCode: '231' },
    { code: 'LY', name: 'LIBYAN ARAB JAMAHIRIYA', dialingCode: '218' },
    { code: 'LI', name: 'LIECHTENSTEIN', dialingCode: '423' },
    { code: 'LT', name: 'LITHUANIA', dialingCode: '370' },
    { code: 'LU', name: 'LUXEMBOURG', dialingCode: '352' },
    { code: 'MO', name: 'MACAO', dialingCode: '853' },
    { code: 'MK', name: 'MACEDONIA (FYROM)', dialingCode: '389' },
    { code: 'MG', name: 'MADAGASCAR', dialingCode: '261' },
    { code: 'MW', name: 'MALAWI', dialingCode: '265' },
    { code: 'MY', name: 'MALAYSIA', dialingCode: '60' },
    { code: 'MV', name: 'MALDIVES', dialingCode: '960' },
    { code: 'ML', name: 'MALI', dialingCode: '223' },
    { code: 'MT', name: 'MALTA', dialingCode: '356' },
    { code: 'MH', name: 'MARSHALL ISLANDS', dialingCode: '692' },
    { code: 'MQ', name: 'MARTINIQUE', dialingCode: '596' },
    { code: 'MR', name: 'MAURITANIA', dialingCode: '222' },
    { code: 'MU', name: 'MAURITIUS', dialingCode: '230' },
    { code: 'YT', name: 'MAYOTTE', dialingCode: '262' },
    { code: 'MX', name: 'MEXICO', dialingCode: '52' },
    { code: 'FM', name: 'MICRONESIA', dialingCode: '691' },
    { code: 'MD', name: 'MOLDOVA, REPUBLIC OF', dialingCode: '373' },
    { code: 'MC', name: 'MONACO', dialingCode: '377' },
    { code: 'MN', name: 'MONGOLIA', dialingCode: '976' },
    { code: 'ME', name: 'MONTENEGRO', dialingCode: '382' },
    { code: 'MS', name: 'MONTSERRAT', dialingCode: '1' },
    { code: 'MA', name: 'MOROCCO', dialingCode: '212' },
    { code: 'MZ', name: 'MOZAMBIQUE', dialingCode: '258' },
    { code: 'MM', name: 'MYANMAR', dialingCode: '95' },
    { code: 'NA', name: 'NAMIBIA', dialingCode: '264' },
    { code: 'NR', name: 'NAURU', dialingCode: '674' },
    { code: 'NP', name: 'NEPAL', dialingCode: '977' },
    { code: 'NL', name: 'NETHERLANDS', dialingCode: '31' },
    { code: 'NC', name: 'NEW CALEDONIA', dialingCode: '687' },
    { code: 'NZ', name: 'NEW ZEALAND', dialingCode: '64' },
    { code: 'NI', name: 'NICARAGUA', dialingCode: '505' },
    { code: 'NE', name: 'NIGER', dialingCode: '227' },
    { code: 'NG', name: 'NIGERIA', dialingCode: '234' },
    { code: 'NU', name: 'NIUE', dialingCode: '683' },
    { code: 'NF', name: 'NORFOLK ISLAND', dialingCode: '672' },
    { code: 'MP', name: 'NORTHERN MARIANA ISLANDS', dialingCode: '1' },
    { code: 'NO', name: 'NORWAY', dialingCode: '47' },
    { code: 'OM', name: 'OMAN', dialingCode: '968' },
    { code: 'PK', name: 'PAKISTAN', dialingCode: '92' },
    { code: 'PW', name: 'PALAU', dialingCode: '680' },
    { code: 'PS', name: 'PALESTINE', dialingCode: '970' },
    { code: 'PA', name: 'PANAMA', dialingCode: '507' },
    { code: 'PG', name: 'PAPUA NEW GUINEA', dialingCode: '675' },
    { code: 'PY', name: 'PARAGUAY', dialingCode: '595' },
    { code: 'PE', name: 'PERU', dialingCode: '51' },
    { code: 'PH', name: 'PHILIPPINES', dialingCode: '63' },
    { code: 'PN', name: 'PITCAIRN', dialingCode: '870' },
    { code: 'PL', name: 'POLAND', dialingCode: '48' },
    { code: 'PT', name: 'PORTUGAL', dialingCode: '351' },
    { code: 'PR', name: 'PUERTO RICO', dialingCode: '1' },
    { code: 'QA', name: 'QATAR', dialingCode: '974' },
    { code: 'RE', name: 'RÉUNION', dialingCode: '262' },
    { code: 'RO', name: 'ROMANIA', dialingCode: '40' },
    { code: 'RU', name: 'RUSSIA', dialingCode: '7' },
    { code: 'RW', name: 'RWANDA', dialingCode: '250' },
    { code: 'BL', name: 'SAINT BARTHÉLEMY', dialingCode: '590' },
    { code: 'SH', name: 'SAINT HELENA', dialingCode: '290' },
    { code: 'KN', name: 'SAINT KITTS AND NEVIS', dialingCode: '1' },
    { code: 'LC', name: 'SAINT LUCIA', dialingCode: '1' },
    { code: 'MF', name: 'SAINT MARTIN', dialingCode: '1' },
    { code: 'PM', name: 'SAINT PIERRE AND MIQUELON', dialingCode: '508' },
    { code: 'VC', name: 'SAINT VINCENT AND THE GRENADINES', dialingCode: '1' },
    { code: 'WS', name: 'SAMOA', dialingCode: '685' },
    { code: 'SM', name: 'SAN MARINO', dialingCode: '378' },
    { code: 'ST', name: 'SAO TOME AND PRINCIPE', dialingCode: '239' },
    { code: 'SA', name: 'SAUDI ARABIA', dialingCode: '966' },
    { code: 'SN', name: 'SENEGAL', dialingCode: '221' },
    { code: 'RS', name: 'SERBIA', dialingCode: '381' },
    { code: 'SC', name: 'SEYCHELLES', dialingCode: '248' },
    { code: 'SL', name: 'SIERRA LEONE', dialingCode: '232' },
    { code: 'SG', name: 'SINGAPORE', dialingCode: '65' },
    { code: 'SK', name: 'SLOVAKIA', dialingCode: '421' },
    { code: 'SI', name: 'SLOVENIA', dialingCode: '386' },
    { code: 'SB', name: 'SOLOMON ISLANDS', dialingCode: '677' },
    { code: 'SO', name: 'SOMALIA', dialingCode: '252' },
    { code: 'ZA', name: 'SOUTH AFRICA', dialingCode: '27' },
    { code: 'ES', name: 'SPAIN', dialingCode: '34' },
    { code: 'LK', name: 'SRI LANKA', dialingCode: '94' },
    { code: 'SD', name: 'SUDAN', dialingCode: '249' },
    { code: 'SR', name: 'SURINAME', dialingCode: '597' },
    { code: 'SJ', name: 'SVALBARD AND JAN MAYEN', dialingCode: '47' },
    { code: 'SZ', name: 'SWAZILAND', dialingCode: '268' },
    { code: 'SE', name: 'SWEDEN', dialingCode: '46' },
    { code: 'CH', name: 'SWITZERLAND', dialingCode: '41' },
    { code: 'SY', name: 'SYRIAN ARAB REPUBLIC', dialingCode: '963' },
    { code: 'TW', name: 'TAIWAN, PROVINCE OF CHINA', dialingCode: '886' },
    { code: 'TJ', name: 'TAJIKISTAN', dialingCode: '992' },
    { code: 'TZ', name: 'TANZANIA, UNITED REPUBLIC OF', dialingCode: '255' },
    { code: 'TH', name: 'THAILAND', dialingCode: '66' },
    { code: 'TL', name: 'TIMOR-LESTE', dialingCode: '670' },
    { code: 'TG', name: 'TOGO', dialingCode: '228' },
    { code: 'TK', name: 'TOKELAU', dialingCode: '690' },
    { code: 'TO', name: 'TONGA', dialingCode: '676' },
    { code: 'TT', name: 'TRINIDAD AND TOBAGO', dialingCode: '1' },
    { code: 'TN', name: 'TUNISIA', dialingCode: '216' },
    { code: 'TR', name: 'TURKEY', dialingCode: '90' },
    { code: 'TM', name: 'TURKMENISTAN', dialingCode: '993' },
    { code: 'TC', name: 'TURKS AND CAICOS ISLANDS', dialingCode: '1' },
    { code: 'TV', name: 'TUVALU', dialingCode: '688' },
    { code: 'UG', name: 'UGANDA', dialingCode: '256' },
    { code: 'UA', name: 'UKRAINE', dialingCode: '380' },
    { code: 'AE', name: 'UNITED ARAB EMIRATES', dialingCode: '971' },
    { code: 'GB', name: 'UNITED KINGDOM', dialingCode: '44' },
    { code: 'US', name: 'UNITED STATES', dialingCode: '1' },
    {
        code: 'UM',
        name: 'UNITED STATES MINOR OUTLYING ISLANDS',
        dialingCode: '1',
    },
    { code: 'UY', name: 'URUGUAY', dialingCode: '598' },
    { code: 'UZ', name: 'UZBEKISTAN', dialingCode: '998' },
    { code: 'VU', name: 'VANUATU', dialingCode: '678' },
    { code: 'VA', name: 'VATICAN CITY', dialingCode: '39' },
    { code: 'VE', name: 'VENEZUELA', dialingCode: '58' },
    { code: 'VN', name: 'VIET NAM', dialingCode: '84' },
    { code: 'VG', name: 'VIRGIN ISLANDS, BRITISH', dialingCode: '1' },
    { code: 'VI', name: 'VIRGIN ISLANDS, U.S.', dialingCode: '1' },
    { code: 'WF', name: 'WALLIS AND FUTUNA', dialingCode: '681' },
    { code: 'EH', name: 'WESTERN SAHARA', dialingCode: '212' },
    { code: 'YE', name: 'YEMEN', dialingCode: '967' },
    { code: 'ZM', name: 'ZAMBIA', dialingCode: '260' },
    { code: 'ZW', name: 'ZIMBABWE', dialingCode: '263' },
];
import { Metadata as $$M } from '@aurelia/metadata';
import { CustomElement as $$CE, IHydrationContext as $$IHC } from '@aurelia/runtime-html';
// @ts-ignore
const controllers = [];
// @ts-ignore
if (module.hot) {
    // @ts-ignore
    module.hot.accept();
    // @ts-ignore
    const hot = module.hot;
    let aurelia = hot.data?.aurelia;
    // @ts-ignore
    document.addEventListener('au-started', (event) => { aurelia = event.detail; });
    const currentClassType = CountryCodeToCountryInfo;
    // @ts-ignore
    const proto = CountryCodeToCountryInfo.prototype;
    // @ts-ignore
    const ogCreated = proto ? proto.created : undefined;
    if (proto) {
        // @ts-ignore
        proto.created = function (controller) {
            // @ts-ignore
            ogCreated && ogCreated.call(this, controller);
            controllers.push(controller);
        };
    }
    // @ts-ignore
    hot.dispose(function (data) {
        // @ts-ignore
        data.controllers = controllers;
        data.aurelia = aurelia;
    });
    if (hot.data?.aurelia) {
        const newDefinition = $$CE.getDefinition(currentClassType);
        $$M.define(newDefinition.name, newDefinition, currentClassType);
        $$M.define(newDefinition.name, newDefinition, newDefinition);
        hot.data.aurelia.container.res[$$CE.keyFrom(newDefinition.name)] = newDefinition;
        const previousControllers = hot.data.controllers ?? [];
        if (previousControllers.length === 0) {
            // @ts-ignore
            hot.invalidate?.();
        }
        // @ts-ignore
        previousControllers.forEach(controller => {
            const values = { ...controller.viewModel };
            const hydrationContext = controller.container.get($$IHC);
            const hydrationInst = hydrationContext.instruction;
            const bindableNames = Object.keys(controller.definition.bindables);
            // @ts-ignore
            Object.keys(values).forEach(key => {
                if (bindableNames.includes(key)) {
                    return;
                }
                // if there' some bindings that target the existing property
                // @ts-ignore
                const isTargettedByBinding = controller.bindings?.some(y => y.ast?.$kind === 2 /* $$EK.AccessScope */
                    && y.ast.name === key && y.targetProperty);
                if (!isTargettedByBinding) {
                    delete values[key];
                }
            });
            const h = controller.host;
            delete controller._compiledDef;
            controller.viewModel = controller.container.invoke(currentClassType);
            controller.definition = newDefinition;
            Object.assign(controller.viewModel, values);
            if (controller._hydrateCustomElement) {
                controller._hydrateCustomElement(hydrationInst, hydrationContext);
            }
            else {
                controller.hE(hydrationInst, hydrationContext);
            }
            h.parentNode.replaceChild(controller.host, h);
            controller.hostController = null;
            controller.deactivate(controller, controller.parent ?? null, 0);
            controller.activate(controller, controller.parent ?? null, 0);
        });
    }
}
