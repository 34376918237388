import { CountryCodeToCountryInfo, FilterCountries } from './ia-flag-dropdown/index';
import { LoadingIndicator } from './elements/loading-indicator';
import { TitleBreadcrumbs } from './elements/title-breadcrumbs';
import { IaaAnchor } from './elements/iaa-anchor';
import { IaModal } from './elements/ia-modal';
import { IaButtonNav } from './elements/ia-button-nav';
import { DateFormatValueConverter } from './value-converters/date-format';
import { CurrencyFormatValueConverter } from './value-converters/currency-format';
import { IaPhoneNumber } from './ia-phone-number';
import { IaFlagDropdown } from './ia-flag-dropdown';
export const Components = [
    LoadingIndicator,
    TitleBreadcrumbs,
    IaaAnchor,
    IaModal,
    IaButtonNav,
    DateFormatValueConverter,
    CurrencyFormatValueConverter,
    CountryCodeToCountryInfo,
    FilterCountries,
    IaFlagDropdown,
    IaPhoneNumber,
];
