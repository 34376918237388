var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __param = (this && this.__param) || function (paramIndex, decorator) {
    return function (target, key) { decorator(target, key, paramIndex); }
};
import { ITokenService } from '../resources/services/token-service';
import { IRouter } from '@aurelia/router';
import { DI } from 'aurelia';
import { IAuthService } from 'aurelia2-auth';
export const IHttpConfig = DI.createInterface('IHttpConfig', (x) => x.singleton(HttpConfig));
let HttpConfig = class HttpConfig {
    auth;
    tokenService;
    router;
    constructor(auth, tokenService, router) {
        this.auth = auth;
        this.tokenService = tokenService;
        this.router = router;
    }
    configure(http) {
        // configure to handle 401 (Unauthorized) exceptions
        // in this scenario, we assume the token has expired
        // we will attempt to refresh the token
        // if successful, update everything and carry on
        // else log the user out and force them to login again
        return http.configure((config) => {
            config.withInterceptor({
                response: async (response) => {
                    if (response.status === 401) {
                        try {
                            //await this.tokenService.postRefreshToken();
                        }
                        catch (err) {
                            this.auth.logout('/');
                        }
                    }
                    return response;
                },
            });
            return config;
        });
    }
};
HttpConfig = __decorate([
    __param(0, IAuthService),
    __param(1, ITokenService),
    __param(2, IRouter),
    __metadata("design:paramtypes", [Object, Object, Object])
], HttpConfig);
export { HttpConfig };
