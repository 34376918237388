var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __param = (this && this.__param) || function (paramIndex, decorator) {
    return function (target, key) { decorator(target, key, paramIndex); }
};
import { log } from '../../resources/utilities/logger';
import { IHttpClient } from '@aurelia/fetch-client';
import { IHttpConfig } from '../../config/http.config';
import { IHttpUtilities } from '../utilities/http-utilities';
import { CacheKey } from '../../resources/models/global-model';
import lzstring from 'lz-string';
import { ErrorMessages } from '../models/error-messages-model';
import { IAppService } from './app-service';
import { DI } from 'aurelia';
export const IPartyService = DI.createInterface('IPartyService', (x) => x.singleton(PartyService));
let PartyService = class PartyService {
    http;
    httpConfig;
    httpUtilities;
    appService;
    host;
    isRequesting = false;
    errorMessages = ErrorMessages;
    constructor(http, httpConfig, httpUtilities, appService) {
        this.http = http;
        this.httpConfig = httpConfig;
        this.httpUtilities = httpUtilities;
        this.appService = appService;
        this.http = httpConfig.configure(http);
        this.host = appService.apiHost;
    }
    async getPartyEntities() {
        try {
            let profile = {};
            // get the entities in parallel
            [profile.phoneEntities, profile.emailEntities, profile.addressEntities] = await Promise.all([
                this.getPartyPhones(),
                this.getPartyEmailAddresses(),
                this.getPartyAddresses(),
            ]);
            return profile;
        }
        catch (err) {
            log.error(err);
        }
    }
    async getPartyEmailAddresses() {
        try {
            const options = this.httpUtilities.createGetOptions();
            const emailEntities = await this.http.fetch(`${this.host}/party/emails`, options);
            const result = await emailEntities.json();
            return result;
        }
        catch (err) {
            log.error(err);
        }
    }
    async getPartyAddresses() {
        try {
            const options = this.httpUtilities.createGetOptions();
            const addressEntities = await this.http.fetch(`${this.host}/party/addresses`, options);
            const result = await addressEntities.json();
            return result;
        }
        catch (err) {
            log.error(err);
        }
    }
    async getPartyPhones() {
        try {
            const options = this.httpUtilities.createGetOptions();
            const phoneEntities = await this.http.fetch(`${this.host}/party/phones`, options);
            const result = await phoneEntities.json();
            result.map((x) => {
                x.countryInfo = {
                    code: x.countryCode,
                };
            });
            return result;
        }
        catch (err) {
            log.error(err);
        }
    }
    async getParty(partyType) {
        switch (partyType) {
            case 'phones':
                this.getPartyPhones();
                break;
            case 'emails':
                this.getPartyEmailAddresses();
                break;
            case 'addresses':
                this.getPartyAddresses();
                break;
        }
    }
    async deleteParty(partyType, model) {
        let url = `${this.host}/party/${partyType}/${model.id}`;
        try {
            const options = this.httpUtilities.createDeleteOptions();
            const deletedParty = await this.http.fetch(url, options);
            if (deletedParty.ok) {
                await this.getParty(partyType);
            }
            return { success: deletedParty.ok };
        }
        catch (err) {
            log.error(err);
        }
    }
    async postParty(partyType, model) {
        let url = `${this.host}/party/${partyType}`;
        let body = await this.mapBody(partyType, model);
        try {
            const options = this.httpUtilities.createPostOptions({
                body: body,
            });
            const addedParty = await this.http.fetch(url, options);
            var result = { success: addedParty.ok };
            if (addedParty.ok) {
                result.value = await addedParty.json();
                await this.getParty(partyType);
            }
            if (addedParty.status === 400) {
                const addResult = await addedParty.json();
                // invalid phone number
                if (addResult.hasOwnProperty('invalid_phone')) {
                    result.status = addedParty.status;
                    result.errorMessage = this.errorMessages.InvalidPhoneNumberForCountry;
                }
                // invalid email
                if (addResult.hasOwnProperty('email')) {
                    result.status = addedParty.status;
                    result.errorMessage = this.errorMessages.InvalidEmailAddress;
                }
            }
            return result;
        }
        catch (err) {
            log.error(err);
            return (result = {
                success: false,
                status: 500,
            });
        }
    }
    async putParty(partyType, model) {
        let url = `${this.host}/party/${partyType}/${model.id}`;
        let body = await this.mapBody(partyType, model);
        try {
            this.isRequesting = true;
            const options = this.httpUtilities.createPutOptions({
                body: body,
            });
            const updatedParty = await this.http.fetch(url, options);
            this.isRequesting = false;
            var result = { success: updatedParty.ok };
            if (updatedParty.ok) {
                result.value = await updatedParty.json();
                await this.getParty(partyType);
            }
            if (updatedParty.status === 400) {
                const addResult = await updatedParty.json();
                // invalid phone number
                if (addResult.hasOwnProperty('invalid_phone')) {
                    result.status = updatedParty.status;
                    result.errorMessage = this.errorMessages.InvalidPhoneNumberForCountry;
                }
                // invalid email
                if (addResult.hasOwnProperty('email')) {
                    result.status = updatedParty.status;
                    result.errorMessage = this.errorMessages.InvalidEmailAddress;
                }
            }
            return result;
        }
        catch (err) {
            log.error(err);
            return (result = {
                success: false,
                status: 500,
            });
        }
    }
    async mapBody(partyType, model) {
        switch (partyType) {
            case 'phones':
                return {
                    Id: model.id,
                    PartyId: model.partyId,
                    EntityTypeId: model.entityTypeId,
                    CountryCode: model.countryInfo.code,
                    TelephoneNumber: model.telephoneNumber,
                    PreferredFlag: model.preferredFlag,
                };
            case 'emails':
                return {
                    Id: model.id,
                    PartyId: model.partyId,
                    EntityTypeId: model.entityTypeId,
                    Email: model.email,
                    PreferredFlag: model.preferredFlag,
                };
            case 'addresses':
                return {
                    Id: model.id,
                    PartyId: model.partyId,
                    EntityTypeId: model.entityTypeId,
                    Address1: model.address1,
                    Address2: model.address2,
                    Address3: model.address3,
                    Suburb: model.suburb,
                    StateCode: model.stateCode,
                    Region: model.region,
                    PostCode: model.postCode,
                    Lat: model.lat,
                    Lon: model.lon,
                    Country: model.country,
                };
        }
    }
    async patchParty(partyType, model) {
        let url = `${this.host}/party/${partyType}/${model.id}/preferred`;
        try {
            const updatedParty = await this.http.fetch(url, {
                method: 'PATCH',
            });
            var result = { success: updatedParty.ok };
            return result;
        }
        catch (err) {
            log.error(err);
            return (result = {
                success: false,
                status: 500,
            });
        }
    }
    async getCountryList() {
        let countryList = [];
        // is the data cached?
        if (sessionStorage.getItem(CacheKey.CountryList)) {
            try {
                // get countryList from cache
                countryList = await Promise.resolve(JSON.parse(lzstring.decompressFromUTF16(sessionStorage.getItem(CacheKey.CountryList))));
            }
            catch (err) {
                log.error(err);
            }
        }
        else {
            try {
                const options = this.httpUtilities.createGetOptions();
                // get countryList
                const result = await this.http.fetch(`${this.host}/reftypes/country`, options);
                countryList = await result.json();
            }
            catch (err) {
                log.error(err);
            }
        }
        // cache countryList
        const cache = lzstring.compressToUTF16(JSON.stringify(countryList));
        sessionStorage.setItem(CacheKey.CountryList, cache);
        return countryList;
    }
};
PartyService = __decorate([
    __param(0, IHttpClient),
    __param(1, IHttpConfig),
    __param(2, IHttpUtilities),
    __param(3, IAppService),
    __metadata("design:paramtypes", [Object, Object, Object, Object])
], PartyService);
export { PartyService };
