var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { customElement } from '@aurelia/runtime-html';
import * as __au2ViewDef from './ia-button-nav.html';
import { bindable } from 'aurelia';
let IaButtonNav = class IaButtonNav {
    iaaClass;
    iaaRoute;
    iaaText;
};
__decorate([
    bindable,
    __metadata("design:type", Object)
], IaButtonNav.prototype, "iaaClass", void 0);
__decorate([
    bindable,
    __metadata("design:type", Object)
], IaButtonNav.prototype, "iaaRoute", void 0);
__decorate([
    bindable,
    __metadata("design:type", Object)
], IaButtonNav.prototype, "iaaText", void 0);
IaButtonNav = __decorate([
    customElement(__au2ViewDef)
], IaButtonNav);
export { IaButtonNav };
import { Metadata as $$M } from '@aurelia/metadata';
import { CustomElement as $$CE, IHydrationContext as $$IHC } from '@aurelia/runtime-html';
// @ts-ignore
const controllers = [];
// @ts-ignore
if (module.hot) {
    // @ts-ignore
    module.hot.accept();
    // @ts-ignore
    const hot = module.hot;
    let aurelia = hot.data?.aurelia;
    // @ts-ignore
    document.addEventListener('au-started', (event) => { aurelia = event.detail; });
    const currentClassType = IaButtonNav;
    // @ts-ignore
    const proto = IaButtonNav.prototype;
    // @ts-ignore
    const ogCreated = proto ? proto.created : undefined;
    if (proto) {
        // @ts-ignore
        proto.created = function (controller) {
            // @ts-ignore
            ogCreated && ogCreated.call(this, controller);
            controllers.push(controller);
        };
    }
    // @ts-ignore
    hot.dispose(function (data) {
        // @ts-ignore
        data.controllers = controllers;
        data.aurelia = aurelia;
    });
    if (hot.data?.aurelia) {
        const newDefinition = $$CE.getDefinition(currentClassType);
        $$M.define(newDefinition.name, newDefinition, currentClassType);
        $$M.define(newDefinition.name, newDefinition, newDefinition);
        hot.data.aurelia.container.res[$$CE.keyFrom(newDefinition.name)] = newDefinition;
        const previousControllers = hot.data.controllers ?? [];
        if (previousControllers.length === 0) {
            // @ts-ignore
            hot.invalidate?.();
        }
        // @ts-ignore
        previousControllers.forEach(controller => {
            const values = { ...controller.viewModel };
            const hydrationContext = controller.container.get($$IHC);
            const hydrationInst = hydrationContext.instruction;
            const bindableNames = Object.keys(controller.definition.bindables);
            // @ts-ignore
            Object.keys(values).forEach(key => {
                if (bindableNames.includes(key)) {
                    return;
                }
                // if there' some bindings that target the existing property
                // @ts-ignore
                const isTargettedByBinding = controller.bindings?.some(y => y.ast?.$kind === 2 /* $$EK.AccessScope */
                    && y.ast.name === key && y.targetProperty);
                if (!isTargettedByBinding) {
                    delete values[key];
                }
            });
            const h = controller.host;
            delete controller._compiledDef;
            controller.viewModel = controller.container.invoke(currentClassType);
            controller.definition = newDefinition;
            Object.assign(controller.viewModel, values);
            if (controller._hydrateCustomElement) {
                controller._hydrateCustomElement(hydrationInst, hydrationContext);
            }
            else {
                controller.hE(hydrationInst, hydrationContext);
            }
            h.parentNode.replaceChild(controller.host, h);
            controller.hostController = null;
            controller.deactivate(controller, controller.parent ?? null, 0);
            controller.activate(controller, controller.parent ?? null, 0);
        });
    }
}
