// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal {
    align-items: center;
    background: rgba(0,0,0, 0.85);
    height: 100%;
    cursor: pointer;
    display: flex;
    justify-content: center;
    left: 0;
    opacity: 0;
    overflow: hidden;
    position: fixed;
    transition: opacity 0.5s ease-in-out, visibility 0.5s linear;
    top: 0;
    visibility: hidden;
    width: 100%;
    z-index: 99999;
}

.modal--no-cursor {
    cursor: default;
}

.modal__inner {
    background: #FFF;
    border-radius: 3px;
    cursor: default;
    padding: 30px 60px;
    text-align: center;
}

.modal--showing {
    opacity: 1;
    visibility: visible;
}
`, "",{"version":3,"sources":["webpack://./src/resources/elements/ia-modal.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,6BAA6B;IAC7B,YAAY;IACZ,eAAe;IACf,aAAa;IACb,uBAAuB;IACvB,OAAO;IACP,UAAU;IACV,gBAAgB;IAChB,eAAe;IACf,4DAA4D;IAC5D,MAAM;IACN,kBAAkB;IAClB,WAAW;IACX,cAAc;AAClB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,gBAAgB;IAChB,kBAAkB;IAClB,eAAe;IACf,kBAAkB;IAClB,kBAAkB;AACtB;;AAEA;IACI,UAAU;IACV,mBAAmB;AACvB","sourcesContent":[".modal {\r\n    align-items: center;\r\n    background: rgba(0,0,0, 0.85);\r\n    height: 100%;\r\n    cursor: pointer;\r\n    display: flex;\r\n    justify-content: center;\r\n    left: 0;\r\n    opacity: 0;\r\n    overflow: hidden;\r\n    position: fixed;\r\n    transition: opacity 0.5s ease-in-out, visibility 0.5s linear;\r\n    top: 0;\r\n    visibility: hidden;\r\n    width: 100%;\r\n    z-index: 99999;\r\n}\r\n\r\n.modal--no-cursor {\r\n    cursor: default;\r\n}\r\n\r\n.modal__inner {\r\n    background: #FFF;\r\n    border-radius: 3px;\r\n    cursor: default;\r\n    padding: 30px 60px;\r\n    text-align: center;\r\n}\r\n\r\n.modal--showing {\r\n    opacity: 1;\r\n    visibility: visible;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
