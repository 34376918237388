var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import * as __au2ViewDef from './ia-phone-number.html';
import { bindable, customElement, valueConverter } from '@aurelia/runtime-html';
/* tslint:disable:max-line-length */
import { getCountryInfoByCountryCode, getCountryInfoByDialingCode, } from './ia-flag-dropdown';
import { GuidGenerator } from './insecure-guid';
import { AsYouType, parsePhoneNumberFromString, formatNumber, } from 'libphonenumber-js';
const defaultPhoneCountry = 'AU';
let PhoneNumberFormatter = class PhoneNumberFormatter {
    toView(value, countryCode = defaultPhoneCountry) {
        if (!value) {
            return value;
        }
        const formatter = createFormatter(countryCode);
        const asYouTypeFormattedNumber = formatter.input(value);
        try {
            const parsedNumber = parsePhoneNumberFromString(asYouTypeFormattedNumber, countryCode);
            const parsedAndFormattedNumber = formatNumber(parsedNumber, 'National');
            if (parsedAndFormattedNumber &&
                parsedNumber.country === defaultPhoneCountry) {
                return parsedAndFormattedNumber;
            }
            else {
                return asYouTypeFormattedNumber;
            }
        }
        catch (e) {
            return asYouTypeFormattedNumber;
        }
    }
};
PhoneNumberFormatter = __decorate([
    valueConverter('formatPhoneNumber')
], PhoneNumberFormatter);
export { PhoneNumberFormatter };
// handler for creating new AsYouTypeFormmatter instances
function createFormatter(country) {
    return new AsYouType(country);
}
let IaPhoneNumber = class IaPhoneNumber {
    phoneType;
    countryInfo = getCountryInfoByCountryCode(defaultPhoneCountry);
    phoneNumber;
    showLabel = true;
    hasFocus = false;
    readOnly = false;
    id;
    input;
    constructor() {
        const guidGenerator = new GuidGenerator();
        this.id = guidGenerator.generateGuidString();
    }
    // empty bind function to keep changed functions from being called
    // when element is initialized
    /* tslint:disable-next-line:no-empty */
    bound() {
        if (!this.countryInfo || !this.countryInfo.code) {
            this.countryInfo = getCountryInfoByCountryCode(defaultPhoneCountry);
        }
        if (this.phoneNumber) {
            this.phoneNumberChanged(this.phoneNumber);
        }
    }
    countryInfoChanged(newCountry, oldCountry) {
        if (!newCountry) {
            this.countryInfo =
                oldCountry || getCountryInfoByCountryCode(defaultPhoneCountry);
        }
        else if (oldCountry &&
            oldCountry.code !== newCountry.code &&
            this.phoneNumber &&
            this.phoneNumber.indexOf(`+${oldCountry.dialingCode}`) === 0) {
            const strippedPhoneNumber = this.phoneNumber.replace(`+${oldCountry.dialingCode}`, '');
            this.phoneNumber = `+${newCountry.dialingCode} ${strippedPhoneNumber}`;
        }
    }
    phoneNumberChanged(newValue) {
        const formatter = createFormatter(this.countryInfo.code);
        formatter.input(newValue);
        const country = formatter.country;
        // @ts-ignore
        const dialingCode = formatter.country_phone_code;
        if (country) {
            this.countryInfo = getCountryInfoByCountryCode(country);
        }
        else if (dialingCode &&
            dialingCode !== this.countryInfo.dialingCode) {
            this.countryInfo = getCountryInfoByDialingCode(dialingCode);
        }
    }
    readOnlyChanged(newVal) {
        if (!newVal) {
            setTimeout(() => {
                if (this.hasFocus) {
                    this.input.focus();
                }
            }, 10);
        }
    }
};
__decorate([
    bindable({ mode: 6 /* BindingMode.twoWay */ }),
    __metadata("design:type", Number)
], IaPhoneNumber.prototype, "phoneType", void 0);
__decorate([
    bindable({ mode: 6 /* BindingMode.twoWay */ }),
    __metadata("design:type", Object)
], IaPhoneNumber.prototype, "countryInfo", void 0);
__decorate([
    bindable({ mode: 6 /* BindingMode.twoWay */ }),
    __metadata("design:type", String)
], IaPhoneNumber.prototype, "phoneNumber", void 0);
__decorate([
    bindable,
    __metadata("design:type", Object)
], IaPhoneNumber.prototype, "showLabel", void 0);
__decorate([
    bindable,
    __metadata("design:type", Object)
], IaPhoneNumber.prototype, "hasFocus", void 0);
__decorate([
    bindable,
    __metadata("design:type", Object)
], IaPhoneNumber.prototype, "readOnly", void 0);
IaPhoneNumber = __decorate([
    customElement({ ...__au2ViewDef, name: 'ia-phone-number', dependencies: [...__au2ViewDef.dependencies, PhoneNumberFormatter] }),
    __metadata("design:paramtypes", [])
], IaPhoneNumber);
export { IaPhoneNumber };
import { Metadata as $$M } from '@aurelia/metadata';
import { CustomElement as $$CE, IHydrationContext as $$IHC } from '@aurelia/runtime-html';
// @ts-ignore
const controllers = [];
// @ts-ignore
if (module.hot) {
    // @ts-ignore
    module.hot.accept();
    // @ts-ignore
    const hot = module.hot;
    let aurelia = hot.data?.aurelia;
    // @ts-ignore
    document.addEventListener('au-started', (event) => { aurelia = event.detail; });
    const currentClassType = PhoneNumberFormatter;
    // @ts-ignore
    const proto = PhoneNumberFormatter.prototype;
    // @ts-ignore
    const ogCreated = proto ? proto.created : undefined;
    if (proto) {
        // @ts-ignore
        proto.created = function (controller) {
            // @ts-ignore
            ogCreated && ogCreated.call(this, controller);
            controllers.push(controller);
        };
    }
    // @ts-ignore
    hot.dispose(function (data) {
        // @ts-ignore
        data.controllers = controllers;
        data.aurelia = aurelia;
    });
    if (hot.data?.aurelia) {
        const newDefinition = $$CE.getDefinition(currentClassType);
        $$M.define(newDefinition.name, newDefinition, currentClassType);
        $$M.define(newDefinition.name, newDefinition, newDefinition);
        hot.data.aurelia.container.res[$$CE.keyFrom(newDefinition.name)] = newDefinition;
        const previousControllers = hot.data.controllers ?? [];
        if (previousControllers.length === 0) {
            // @ts-ignore
            hot.invalidate?.();
        }
        // @ts-ignore
        previousControllers.forEach(controller => {
            const values = { ...controller.viewModel };
            const hydrationContext = controller.container.get($$IHC);
            const hydrationInst = hydrationContext.instruction;
            const bindableNames = Object.keys(controller.definition.bindables);
            // @ts-ignore
            Object.keys(values).forEach(key => {
                if (bindableNames.includes(key)) {
                    return;
                }
                // if there' some bindings that target the existing property
                // @ts-ignore
                const isTargettedByBinding = controller.bindings?.some(y => y.ast?.$kind === 2 /* $$EK.AccessScope */
                    && y.ast.name === key && y.targetProperty);
                if (!isTargettedByBinding) {
                    delete values[key];
                }
            });
            const h = controller.host;
            delete controller._compiledDef;
            controller.viewModel = controller.container.invoke(currentClassType);
            controller.definition = newDefinition;
            Object.assign(controller.viewModel, values);
            if (controller._hydrateCustomElement) {
                controller._hydrateCustomElement(hydrationInst, hydrationContext);
            }
            else {
                controller.hE(hydrationInst, hydrationContext);
            }
            h.parentNode.replaceChild(controller.host, h);
            controller.hostController = null;
            controller.deactivate(controller, controller.parent ?? null, 0);
            controller.activate(controller, controller.parent ?? null, 0);
        });
    }
}
