export const LoginRoutes = [
    // Auth
    {
        path: ['', 'login'],
        component: import('../components/login'),
        title: 'Login',
        id: 'login',
        data: {
            auth: false,
            nav: false
        }
    },
    {
        path: '*',
        redirectTo: '/'
    }
];
export const AuthenticatedRoutes = [
    {
        path: ['', 'home'],
        component: import('../components/home'),
        title: 'Home',
        id: 'home',
        data: {
            auth: true,
            nav: true,
            icon: 'home',
            pathAlias: '/home'
        },
    },
    // Certificates
    {
        path: 'certificates',
        component: import('../components/certificates'),
        title: 'Certificates',
        id: 'certificates',
        data: {
            auth: true,
            nav: false,
        },
    },
    // Certificate
    {
        path: 'certificate/:certificateId',
        component: import('../components/certificate'),
        title: 'Certificate',
        id: 'certificate',
        data: {
            auth: true,
            nav: false,
        },
    },
    // Contact
    {
        path: ['contact', 'contact/:type'],
        component: import('../components/contact'),
        title: 'Contact',
        id: 'contact',
        data: {
            auth: true,
            nav: true,
            icon: 'phone',
            pathAlias: '/contact'
        },
    },
    // Payment Due Details
    {
        path: 'payment-details',
        component: import('../components/payment-details'),
        title: 'Payments Due',
        id: 'payment-details',
        data: {
            auth: true,
            nav: false,
        },
    },
    // Payment Invoice
    {
        path: 'payment-invoice/:id',
        component: import('../components/payment-invoice'),
        title: 'Pay Invoice',
        id: 'payment-invoice',
        data: {
            auth: true,
            nav: false,
        },
    },
    // Policy
    {
        path: 'policy/:policyVersionId',
        component: import('../components/policy'),
        title: 'Policy',
        id: 'policy',
        data: {
            auth: true,
            nav: false,
        },
    },
    // Claim
    {
        path: 'claim',
        component: import('../components/claim'),
        title: 'Claim',
        id: 'claim',
        data: {
            auth: true,
            nav: false,
        },
    },
    // Profile
    {
        path: 'profile/:clientCode/:action?',
        component: import('../components/profile/view-profile'),
        title: 'Profile',
        id: 'profile',
        data: {
            auth: true,
            nav: false,
        },
    },
    // Switch Customer
    {
        path: 'switchcustomer',
        component: import('../components/switch-customer'),
        title: 'Switch Customer',
        id: 'switch-customer',
        data: {
            auth: true,
            nav: false,
        },
    },
];
// @inject(AuthService, TokenService)
// export class AuthorizeStep {
//   constructor(auth, tokenApi) {
//     Object.assign(this, { auth, tokenApi });
//   }
//   async run(navigationInstruction, next) {
//     log.debug('routes.config::authorizeStep');
//     if (navigationInstruction.getAllInstructions().some(i => i.config.auth)) {
//       // has the user token expired?
//       log.debug(`session storage: ${sessionStorage.getItem(AuthKey.expires)}`);
//       log.debug(
//         `decompressed: ${lzstring.decompressFromUTF16(
//           sessionStorage.getItem(AuthKey.expires)
//         )}`
//       );
//       const expireTime = new Date(
//         lzstring.decompressFromUTF16(sessionStorage.getItem(AuthKey.expires))
//       );
//       const now = new Date();
//       if (now > expireTime) {
//         log.debug('token expired...attempting to renew');
//         try {
//           await this.tokenApi.postRefreshToken();
//         } catch (err) {
//           log.debug(err);
//           await this.auth.logout();
//         }
//       }
//       if (!this.auth.isAuthenticated()) {
//         return next.cancel(new Redirect(this.auth.config.loginRoute));
//       }
//     }
//     return next();
//   }
// }
