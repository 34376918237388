var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __param = (this && this.__param) || function (paramIndex, decorator) {
    return function (target, key) { decorator(target, key, paramIndex); }
};
import { IAuthService } from 'aurelia2-auth';
import { log } from '../../resources/utilities/logger';
import { IHttpClient } from '@aurelia/fetch-client';
import authConfig from '../../config/auth.config';
import { AuthKey } from '../models/auth-model';
import lzstring from 'lz-string';
import { IAppService } from './app-service';
import { DI } from 'aurelia';
export const ITokenService = DI.createInterface('ITokenService', (x) => x.singleton(TokenService));
let TokenService = class TokenService {
    http;
    appService;
    auth;
    host;
    constructor(http, appService, auth) {
        this.http = http;
        this.appService = appService;
        this.auth = auth;
        this.host = appService.apiHost;
    }
    // we have to venture away from our standard for working with fetch in services here
    // due to the way we have to format this request and the way that fetch handles POST parameters
    async postRefreshToken() {
        const params = `grant_type=refresh_token&client_id=${authConfig.client_id}&refresh_token=${lzstring.decompressFromUTF16(sessionStorage.getItem(AuthKey.refresh_token))}`;
        const options = {
            method: 'POST',
            body: params,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
        };
        try {
            const updatedToken = await this.http.fetch(`${this.host}/token`, options);
            const result = await updatedToken.json();
            // store the updated app token, refresh token, and expires
            this.auth.setToken(result.access_token);
            // sessionStorage.setItem(AuthKey.access_token, result.access_token);
            sessionStorage.setItem(AuthKey.expires, lzstring.compressToUTF16(Object.entries(result).filter((entry) => entry[0].includes('.expires'))[0][1]));
            sessionStorage.setItem(AuthKey.refresh_token, lzstring.compressToUTF16(result.refresh_token));
        }
        catch (err) {
            // the token has expired but we are getting an error when we attempt to refresh it
            // the user will be logged out and redirected to the login view automatically by the auth system
            log.error(err);
        }
    }
};
TokenService = __decorate([
    __param(0, IHttpClient),
    __param(1, IAppService),
    __param(2, IAuthService),
    __metadata("design:paramtypes", [Object, Object, Object])
], TokenService);
export { TokenService };
