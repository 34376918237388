var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __param = (this && this.__param) || function (paramIndex, decorator) {
    return function (target, key) { decorator(target, key, paramIndex); }
};
import { lifecycleHooks } from 'aurelia';
import { IRouter } from '@aurelia/router';
import { IAuthService } from 'aurelia2-auth';
let RouteLoadedHook = class RouteLoadedHook {
    loading(vm, params, next, current) {
        if (!next?.data?.noScrollToTop) {
            window.scrollTo(0, 0);
        }
        return true;
    }
};
RouteLoadedHook = __decorate([
    lifecycleHooks()
], RouteLoadedHook);
export { RouteLoadedHook };
let RouterAuthHook = class RouterAuthHook {
    router;
    auth;
    constructor(router, auth) {
        this.router = router;
        this.auth = auth;
    }
    canLoad(viewModel, params, instruction, navigation) {
        const requiresAuth = instruction?.route?.match?.data?.auth ?? false;
        const isAuthenticated = this.auth.isAuthenticated();
        if (requiresAuth && !isAuthenticated) {
            window.location.href = '/';
            return false;
        }
        return true;
    }
};
RouterAuthHook = __decorate([
    lifecycleHooks(),
    __param(0, IRouter),
    __param(1, IAuthService),
    __metadata("design:paramtypes", [Object, Object])
], RouterAuthHook);
export { RouterAuthHook };
