<section class="sticky-top">
  <header class="g-pt-15 g-pb-9">
    <div class="container">
      <div class="row">

        <div class="col-sm-6 col-8">
          <div if.bind="adviserDetails.companyOfficeLogoUrl" class="logo">
            <a load="home">
              <img src="${adviserDetails.companyOfficeLogoUrl}" class="img-fluid" alt="logo">
            </a>
          </div>
        </div>

        <div class="col-sm-6 col-4 profile-block" style="display: inline-block">
          <div class="user float-right g-pr-3" style="cursor: pointer" click.trigger="e => onProfileClick(e)">
           <!-- <div class="notifications">
              <i class="float-left far fa-bell notification-icon"></i>
            </div> -->
            
            <i class="float-left fal fa-user-circle avatar"></i>
            <div class="user-profile g-pl-15">
              <h3>Welcome
                <span class="g-pl-4" title="${userProfile.clientName}">${userProfile.clientName}</span>
              </h3>
              <span>Client code ${userProfile.clientCode}</span>
            </div>
          <div class="profile-menu ${showProfileMenu ? 'profile-menu-show' : ''}">
              <a click.trigger="() => onCloseProfile()" class="profile-close-btn">&times;</a>              
              <ul>
                <li  repeat.for="link of profileMenuLinks" class="profile-menu-item">
                  <a class="nav-link" click.trigger="() => onProfileItemClick(link)">
                    ${link.display} 
                    <span if.bind="link.subLinks && !link.showSubLinks">
                        <i class="fas fa-sort-down profile-sublinks-arrow"></i>
                    </span>
                    <span if.bind="link.subLinks && link.showSubLinks">
                        <i class="fas fa-sort-up profile-sublinks-arrow profile-sublinks-arrow-up"></i>
                    </span>
                  </a>
                  <div class="profile-menu-sublinks  ${link.showSubLinks ? 'profile-menu-sublinks-show' : ''}" if.bind="link.subLinks">
                      <ul>
                          <li  repeat.for="subLink of link.subLinks" class="profile-menu-item">
                              <a class="nav-link" click.trigger="() => onProfileItemClick(subLink)">
                                ${subLink.display}
                              </a>
                          </li>                  
                      </ul>
                  </div>
                </li>                  
              </ul>              

            </div>
          </div>

        </div>

      </div>
    </div>
  </header>

  <nav class="navbar">
    <div class="container">
      <div class="navbar-nav text-uppercase">

        <a repeat.for="route of routes" load="${route.data.pathAlias}" class="nav-item nav-link" if.bind="route.data.nav">
          <i class="fas fa-${route.data.icon} fa-lg g-pr-5" aria-hidden="true"></i>&nbsp;${route.title}
        </a>

      </div>
    </div>
  </nav>
</section>