export const ContactModel = {
    Title: 'contact',
    Breadcrumbs: [
        {
            index: 0,
            title: 'home',
            route: '/',
            active: true,
        },
        {
            index: 1,
            title: 'contact us',
            route: '',
            active: false,
        },
    ],
};
export const ContactType = {
    PhoneNumber: 'Phone',
    EmailAddress: 'Email',
    Address: 'Address',
};
