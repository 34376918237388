<div class="modal ${showing ? 'modal--showing' : 'modal--hidden'} ${!clickClose ? 'modal--no-cursor' : ''}">

  <div class="modal__inner">
      <h3 if.bind="heading">${heading}</h3>
      <div class="modal__inner__body">
          <au-slot></au-slot>

          <template if.bind="type == 'confirm'">
              <button type="button" click.trigger="confirmAgreeCallback()" disabled.bind="!confirmButtonState || isModalProcessing">${confirmButtonText}</button>
              <button type="button" class="btn-cancel" click.trigger="confirmCancelCallback()" disabled.bind="isModalProcessing">Cancel</button>
          </template>

          <template if.bind="type == 'okay'">
              <button type="button" click.trigger="showing = false">Okay</button>
          </template>
      </div>
  </div>
</div>
